import React, { useState } from 'react';
import { Table, Pagination, Group, Button, Title } from "@mantine/core";
import CSVDownloader from 'react-csv-downloader';

const TablaPorSeguimiento = ({ data }) => {
    const [activePage, setActivePage] = useState(1);
    const rowsPerPage = 5;

    // Agrupar y sumar cuenta_muestra por tipo_seguimiento
    const dataPorSeguimiento = data.reduce((acc, item) => {
        const { tipo_seguimiento, cuenta_muestra_estatus_ACTIVA } = item;
        if (!acc[tipo_seguimiento]) {
            acc[tipo_seguimiento] = 0;
        }
        acc[tipo_seguimiento] += cuenta_muestra_estatus_ACTIVA;
        return acc;
    }, {});

    // Convertir el objeto resultante en un array para renderizarlo en la tabla
    const dataArray = Object.keys(dataPorSeguimiento).map((key) => ({
        tipo_seguimiento: key,
        sumaCuentaMuestraPorSeguimiento: dataPorSeguimiento[key]
    }));

    // Calcular los datos para la página actual
    const startIndex = (activePage - 1) * rowsPerPage;
    const paginatedData = dataArray.slice(startIndex, startIndex + rowsPerPage);

    // Definir las columnas para el CSV
    const csvHeaders = [
        { id: 'tipo_seguimiento', displayName: 'Programa' },
        { id: 'sumaCuentaMuestraPorSeguimiento', displayName: 'Muestras' },
    ];

    const csvData = dataArray.map((data) => {
        const tipo_seguimiento = data.tipo_seguimiento ? data.tipo_seguimiento.replace(/,/g, '') : '';
    
        return {
            tipo_seguimiento: tipo_seguimiento,
            sumaCuentaMuestraPorSeguimiento: data.sumaCuentaMuestraPorSeguimiento,
        };
    });

    return (
        <div>
            <Group justify="space-between">
                <Title order={4}>
                    Tabla por Programa
                </Title>
            </Group>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead>
                    <tr>
                        <th>Programa</th>
                        <th>Muestras</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.tipo_seguimiento}</td>
                            <td>{item.sumaCuentaMuestraPorSeguimiento}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Group justify="flex-end" mb="md">
                <CSVDownloader
                    filename={'tabla_por_programa'}
                    columns={csvHeaders}
                    datas={csvData}
                >
                    <Button>Descargar</Button>
                </CSVDownloader>
                <Pagination
                    page={activePage}
                    onChange={setActivePage}
                    total={Math.ceil(dataArray.length / rowsPerPage)}
                />
            </Group>
            
        </div>
    );
};

export default TablaPorSeguimiento;

