import React from 'react';
import { Card } from '@mantine/core';
import { useAuth } from '../context/AuthContext';


function HomePage() {
  const { user } = useAuth();
  return (
    <div>
      <Card>
        <div>
          <h1>Hola! {user.username || user.email}</h1>

        </div>
      </Card>
    </div>
  );
}

export default HomePage;