import React, { useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Button, Group, Text, Title } from '@mantine/core';

const BarChartPorOrigen = ({ data }) => {
    const chartContainerRef = useRef(null);

    const handleFullScreen = () => {
        const elem = chartContainerRef.current;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { // IE/Edge
            elem.msRequestFullscreen();
        }
    };

    const handleFullScreenChange = () => {
        if (document.fullscreenElement === chartContainerRef.current) {
            chartContainerRef.current.classList.add('fullscreen-background');
        } else {
            chartContainerRef.current.classList.remove('fullscreen-background');
        }
    };

    React.useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
    }, []);

    // Agrupar y sumar cuenta_muestra_estatus_ACTIVA por nombre_origen
    const dataPorOrigen = data.reduce((acc, item) => {
        const { nombre_origen, cuenta_muestra_estatus_ACTIVA } = item;
        if (!acc[nombre_origen]) {
            acc[nombre_origen] = 0;
        }
        acc[nombre_origen] += cuenta_muestra_estatus_ACTIVA;
        return acc;
    }, {});

    // Convertir el objeto resultante en un array para el gráfico
    const chartData = Object.keys(dataPorOrigen).map((key) => ({
        nombre_origen: key,
        cuenta_muestra_estatus_ACTIVA: dataPorOrigen[key]
    }));

    return (
        <div>
            <Group justify="space-between">
                <Title order={4}>
                    Gráfica por Origen
                </Title>
                <Button variant="outline" color="#005187" onClick={handleFullScreen} style={{ marginBottom: '10px' }}>
                    Pantalla Completa
                </Button>
            </Group>

            <div ref={chartContainerRef} style={{ position: 'relative', width: '100%', height: '350px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 80 }}>   
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="nombre_origen"
                            tick={{ angle: -45, textAnchor: 'end', fontSize: 10 }}
                            interval={0}
                            height={60}
                        />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="cuenta_muestra_estatus_ACTIVA" fill="#005187" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default BarChartPorOrigen;
