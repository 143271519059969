import React, { useState } from 'react';
import { Table, Button, Group, Pagination, Title } from '@mantine/core';
import CSVDownloader from 'react-csv-downloader';

const TablaPorOrigen = ({ data }) => {
    const [activePage, setActivePage] = useState(1);
    const rowsPerPage = 6;

    // Agrupar y sumar cuenta_muestra por nombre_origen
    const dataPorOrigen = data.reduce((acc, item) => {
        const { nombre_origen, cuenta_muestra_estatus_ACTIVA } = item;
        if (!acc[nombre_origen]) {
            acc[nombre_origen] = 0;
        }
        acc[nombre_origen] += cuenta_muestra_estatus_ACTIVA;
        return acc;
    }, {});

    // Convertir el objeto resultante en un array para renderizarlo en la tabla
    const dataArray = Object.keys(dataPorOrigen).map((key) => ({
        nombre_origen: key,
        sumaCuentaMuestraPorOrigen: dataPorOrigen[key]
    }));

    // Calcular los datos para la página actual
    const startIndex = (activePage - 1) * rowsPerPage;
    const paginatedData = dataArray.slice(startIndex, startIndex + rowsPerPage);

    // Definir las columnas para el CSV
    const csvHeaders = [
        { id: 'nombre_origen', displayName: 'Origen' },
        { id: 'sumaCuentaMuestraPorOrigen', displayName: 'Muestras' },
    ];

    const csvData = dataArray.map((data) => {
        const nombre_origen = data.nombre_origen ? data.nombre_origen.replace(/,/g, '') : '';
    
        return {
            nombre_origen: nombre_origen,
            sumaCuentaMuestraPorOrigen: data.sumaCuentaMuestraPorOrigen,
        };
    });

    return (
        <div>
            <Group justify="space-between">
                <Title order={4}>
                    Tabla por Origen
                </Title>

            </Group>
            
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead>
                    <tr>
                        <th>Origen</th>
                        <th>Muestras</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.nombre_origen}</td>
                            <td>{item.sumaCuentaMuestraPorOrigen}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Group justify="flex-end" mb="md">
                <CSVDownloader
                    filename={'tabla_por_origen'}
                    columns={csvHeaders}
                    datas={csvData}
                >
                    <Button>Descargar</Button>
                </CSVDownloader>
                <Pagination
                    page={activePage}
                    onChange={setActivePage}
                    total={Math.ceil(dataArray.length / rowsPerPage)}
                />
            </Group>
        </div>
    );
};

export default TablaPorOrigen;

