import React, { useEffect, useState } from 'react';
import { Select, Group, Autocomplete, MultiSelect } from '@mantine/core';
import axios from 'axios';

function FiltrosSanitaria2({ setFilters, filters }) {
    const [periodos, setPeriodos] = useState([]);
    const [origenes, setOrigenes] = useState([]);
    const [estudios, setEstudios] = useState([]);
    const [meses, setMeses] = useState([]);
    const [programas, setProgramas] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    
    // Utiliza la URL de la API desde las variables de entorno
const API_URL = process.env.REACT_APP_API_URL;


    useEffect(() => {
        const fetchPeriodos = async () => {
            try {
                const response = await axios.get(`${API_URL}/filtroperiodos`);
                const periodos = response.data;
                //console.log("Estos son los periodos obtenidos", periodos);


                // Obtener la lista de periodos desde los objetos y filtrar duplicados
                const uniquePeriodos = Array.from(new Set(periodos.map(item => item.periodo.toString())));

                const periodosOptions = uniquePeriodos.map(periodo => ({
                    label: periodo,
                    value: periodo
                }));

                setPeriodos(periodosOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };

        const fetchOrigenes = async () => {
            try {
                const response = await axios.get(`${API_URL}/filtroorigenes`);
                const origenes = response.data;
                //console.log("Origenessss", origenes);

                // Obtener la lista de origenes desde los objetos y filtrar duplicados
                const uniqueOrigenes = Array.from(new Set(origenes.map(item => item.nombre_origen)));

                const origenesOptions = uniqueOrigenes.map(origen => ({
                    label: origen,
                    value: origen
                }));

                setOrigenes(origenesOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };

        const fetchEstudios = async () => {
            try {
                const response = await axios.get(`${API_URL}/filtroestudios`);
                const estudios = response.data;
                //console.log("estudios", estudios);

                // Obtener la lista de estudios desde los objetos y filtrar duplicados
                const uniqueEstudios = Array.from(new Set(estudios.map(item => item.nombre_estudio)));

                const estudiosOptions = uniqueEstudios.map(estudio => ({
                    label: estudio,
                    value: estudio
                }));

                setEstudios(estudiosOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };

        const mesesArray = [
            { label: "ENERO", value: '1' },
            { label: "FEBRERO", value: '2' },
            { label: "MARZO", value: '3' },
            { label: "ABRIL", value: '4' },
            { label: "MAYO", value: '5' },
            { label: "JUNIO", value: '6' },
            { label: "JULIO", value: '7' },
            { label: "AGOSTO", value: '8' },
            { label: "SEPTIEMBRE", value: '9' },
            { label: "OCTUBRE", value: '10' },
            { label: "NOVIEMBRE", value: '11' },
            { label: "DICIEMBRE", value: '12' }
        ];

        setMeses(mesesArray);

        const fetchTiposProgramas = async () => {
            try {
                const response = await axios.get(`${API_URL}/filtrotiposprogramas`);
                const tiposprogramas = response.data;
                //console.log("tipoprograma", tiposprogramas);

                // Obtener la lista de tipo de programas desde los objetos y filtrar duplicados
                const uniqueTiposprogramas = Array.from(new Set(tiposprogramas.map(item => item.tipo_seguimiento)));

                const tiposProgramas = uniqueTiposprogramas.map(tipo_seguimiento => ({
                    label: tipo_seguimiento,
                    value: tipo_seguimiento
                }));

                setProgramas(tiposProgramas);
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };

        const fetchSucursales = async () => {
            try {
                const response = await axios.get(`${API_URL}/filtrosucursales`);
                const nombresucursales = response.data;
                //console.log("sucursales", nombresucursales);

                // Obtener la lista de tipo de programas desde los objetos y filtrar duplicados
                const uniqueSucursales = Array.from(new Set(nombresucursales.map(item => item.nombre_sucursal)));

                const nombresSucursales = uniqueSucursales.map(nombre_sucursal => ({
                    label: nombre_sucursal,
                    value: nombre_sucursal
                }));

                setSucursales(nombresSucursales);
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };


        fetchPeriodos();
        fetchOrigenes();
        fetchEstudios();
        fetchTiposProgramas();
        fetchSucursales();
    }, []);

    return (
        <div style={{ backgroundColor: 'white', marginBottom: '10px' }}>
            <Group style={{ display: 'flex', justifyContent:'center' }}>
                <Select
                    label="Periodo"
                    placeholder="Periodo"
                    allowDeselect={false}
                    data={periodos}
                    value={filters.periodo}
                    onChange={(value) => setFilters({ ...filters, periodo: value })}
                    name="periodo"
                    style={{ width: '100%', maxWidth: '500px' }} // Establece un ancho máximo de 150px y ocupa todo el ancho disponible en dispositivos móviles
                />
                <Select
                    label="Mes"
                    placeholder="mes"
                    allowDeselect={false}
                    data={meses}
                    value={filters.mes}
                    onChange={(value) => setFilters({ ...filters, mes: value })}
                    name="nombre_mes"
                    style={{ width: '100%', maxWidth: '500px' }} // Establece un ancho máximo de 150px y ocupa todo el ancho disponible en dispositivos móviles
                />
                <Autocomplete
                    label="Sucursal"
                    placeholder="sucursal"
                    data={sucursales}
                    value={filters.sucursal}
                    onChange={(value) => setFilters({ ...filters, nombre_sucursal: value })}
                    name="nombre_sucursal"
                    style={{ width: '100%', maxWidth: '500px' }} // Establece un ancho máximo de 150px y ocupa todo el ancho disponible en dispositivos móviles
                />


                <MultiSelect
                    label="Tipo programa"
                    placeholder="tipo programa"
                    checkIconPosition="left"

                    data={programas}
                    value={filters.tipo_seguimiento}
                    onChange={(value) => setFilters({ ...filters, tipo_seguimiento: value })}
                    searchable
                    nothingFound="No options"
                    name="tipo_seguimiento"
                    style={{ width: '100%', maxWidth: '500px' }} // Establece un ancho máximo de 150px y ocupa todo el ancho disponible en dispositivos móviles
                />


                <MultiSelect
                    label="Origen"
                    placeholder="Selecciona origen"
                    checkIconPosition="left"

                    data={origenes}
                    value={filters.origen}
                    onChange={(value) => setFilters({ ...filters, origen: value })}
                    searchable
                    nothingFound="No options"
                    name="origen"
                    style={{ width: '100%', maxWidth: '500px' }} // Establece un ancho máximo de 150px y ocupa todo el ancho disponible en dispositivos móviles
                />

                <MultiSelect
                    label="Paquete"
                    placeholder="paquete"
                    checkIconPosition="left"


                    data={estudios}
                    value={filters.estudio}
                    onChange={(value) => setFilters({ ...filters, estudio: value })}
                    searchable
                    nothingFound="No options"

                    name="estudio"
                    style={{ width: '100%', maxWidth: '500px' }} // Establece un ancho máximo de 150px y ocupa todo el ancho disponible en dispositivos móviles
                />


            </Group>
        </div>
    );
}

export default FiltrosSanitaria2;