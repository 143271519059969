import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';



const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const dispatch = useDispatch();

  
  useEffect(() => {
    // Muestra el indicador de carga al iniciar la verificación y lo oculta al finalizar.
    // Este enfoque asume que la lógica dentro de useAuth eventualmente cambia el estado de `loading`.
    if (loading) {
      dispatch(ShowLoading());
    } else {
      dispatch(HideLoading());
    }
  }, [loading, dispatch]);

  if (loading) {
    return null; // o tu componente de carga personalizado
  }

  // Si el usuario está autenticado, renderiza los hijos protegidos.
  // De lo contrario, redirige al usuario a la página de inicio de sesión.
  return user ? children : <Navigate to="/login" replace />;


};

export default ProtectedRoute;