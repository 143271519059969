import React, { useRef } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { Button, Group, Text, Title } from '@mantine/core';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#005187'];

const DonutChartTipoSeguimiento = ({ data }) => {
    const chartContainerRef = useRef(null);

    const handleFullScreen = () => {
        const elem = chartContainerRef.current;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { // IE/Edge
            elem.msRequestFullscreen();
        }
    };

    const handleFullScreenChange = () => {
        if (document.fullscreenElement === chartContainerRef.current) {
            chartContainerRef.current.classList.add('fullscreen-background');
        } else {
            chartContainerRef.current.classList.remove('fullscreen-background');
        }
    };

    React.useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
    }, []);

    // Agrupar y sumar cuenta_muestra_estatus_ACTIVA por tipo_seguimiento
    const dataPorTipoSeguimiento = data.reduce((acc, item) => {
        const { tipo_seguimiento, cuenta_muestra_estatus_ACTIVA } = item;
        if (!acc[tipo_seguimiento]) {
            acc[tipo_seguimiento] = 0;
        }
        acc[tipo_seguimiento] += cuenta_muestra_estatus_ACTIVA;
        return acc;
    }, {});

    // Convertir el objeto resultante en un array para el gráfico
    const chartData = Object.keys(dataPorTipoSeguimiento).map((key) => ({
        name: key,
        value: dataPorTipoSeguimiento[key]
    }));

    return (
        <div>
            <Group justify="space-between">
                <Title order={4}>
                    Gráfica por Programa
                </Title>
                <Button variant="outline" color="#005187" onClick={handleFullScreen} style={{ marginBottom: '10px' }}>
                    Pantalla Completa
                </Button>
            </Group>

            <div ref={chartContainerRef} style={{ position: 'relative', width: '100%', height: '350px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={chartData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            innerRadius={120}

                            outerRadius={150}
                            fill="#8884d8"
                            dataKey="value"
                            paddingAngle={5}
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default DonutChartTipoSeguimiento;
