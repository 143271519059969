import React from 'react';
import { Card, Table, Group, Title, Button } from "@mantine/core";
import CSVDownloader from 'react-csv-downloader';



function TablaSanitaria({ data }) {
    // Definir las columnas para el CSV
    const csvHeaders = [
        { id: 'periodo', displayName: 'Periodo' },
        { id: 'mes', displayName: 'Mes' },
        { id: 'nombre_sucursal', displayName: 'Sucursal' },
        { id: 'tipo_seguimiento', displayName: 'Programa' },
        { id: 'nombre_estudio', displayName: 'Estudio' },
        { id: 'nombre_origen', displayName: 'Origen' },
        { id: 'cuenta_muestra', displayName: 'Muestras' },
        { id: 'cuenta_muestra_estatus_ACTIVA', displayName: 'Activas' },
        { id: 'cuenta_muestra_estatus_RECHAZADA', displayName: 'Rechazadas' },

    ];

    const csvData = data.map((data) => {
        const tipo_seguimiento = data.tipo_seguimiento ? data.tipo_seguimiento.replace(/,/g, '') : '';
        const nombre_estudio = data.nombre_estudio ? data.nombre_estudio.replace(/,/g, '') : '';
        const nombre_origen = data.nombre_origen ? data.nombre_origen.replace(/,/g, '') : '';

        return {
            periodo: data.periodo,
            mes: data.mes,
            nombre_sucursal: data.nombre_sucursal,
            tipo_seguimiento: tipo_seguimiento,
            nombre_estudio: nombre_estudio,
            nombre_origen: nombre_origen,
            cuenta_muestra: data.cuenta_muestra,
            cuenta_muestra_estatus_ACTIVA: data.cuenta_muestra_estatus_ACTIVA,
            cuenta_muestra_estatus_RECHAZADA: data.cuenta_muestra_estatus_RECHAZADA

        };
    });

    return (
        <div>
            <Card >
                <Group justify="space-between">
                    <Title order={4}>
                        Tabla de Muestras
                    </Title>

                </Group>
                <div style={{ overflowX: 'auto' }}>
                    <Table
                        style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                        verticalSpacing="xl"
                        fontSize="sm"
                        mb={10}
                        striped
                        withRowBorders={true}
                        className="my-custom-table"
                    >
                        <thead>
                            <tr>
                                <th>Periodo</th>
                                <th>Mes</th>
                                <th>Sucursal</th>
                                <th>Tipo Seguimiento</th>
                                <th>Nombre Estudio</th>
                                <th>Nombre Origen</th>
                                <th>Cuenta Muestra</th>
                                <th>Cuenta Muestra Activa</th>
                                <th>Cuenta Muestra Rechazada</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.periodo}</td>
                                    <td>{item.mes}</td>
                                    <td>{item.nombre_sucursal}</td>
                                    <td>{item.tipo_seguimiento}</td>
                                    <td>{item.nombre_estudio}</td>
                                    <td>{item.nombre_origen}</td>
                                    <td>{item.cuenta_muestra}</td>
                                    <td>{item.cuenta_muestra_estatus_ACTIVA}</td>
                                    <td>{item.cuenta_muestra_estatus_RECHAZADA}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Group justify="flex-end" mb="md">
                    <CSVDownloader
                        filename={'tabla_por_programa'}
                        columns={csvHeaders}
                        datas={csvData}
                    >
                        <Button>Descargar</Button>
                    </CSVDownloader>

                </Group>
            </Card>
        </div>
    );
}

export default TablaSanitaria;
