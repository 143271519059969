import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from "../redux/alertsSlice";

function PublicRoute({ children }) {
    const { user, loading } = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        // Asumiendo que el manejo de la visibilidad del indicador de carga se debe realizar
        // en función del estado de 'loading', se podría despachar aquí. Sin embargo,
        // si el estado 'loading' no cambia en este componente, podría no ser necesario.
        if (loading) {
            dispatch(ShowLoading());
        } else {
            dispatch(HideLoading());
        }
    }, [loading, dispatch]);

    // Si hay un usuario, redirigir al inicio.
    if (user) {
        return <Navigate to="/" replace />;
    }

    // Si no hay usuario y no está cargando, mostrar el formulario de Login.
    if (!loading && user === null) {
        return children;
    }

    // Si está cargando o user es null, evitamos renderizar cualquier cosa o manejarlo de forma diferente.
    return null;
}

export default PublicRoute;