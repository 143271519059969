import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';

import Login from "../pages/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from './PublicRoute';
import Sanitaria from '../pages/Sanitaria';
import { useAuth } from '../context/AuthContext';
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from '../redux/alertsSlice';
import { supabase } from '../supabaseClient';


const MainRouter = () => {
    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        //console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);

                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        } else {
            //console.log("no hay usuario")
            dispatch(HideLoading());

        }
    }, [user, dispatch]);


    return (
        <div>
            <main>
                <Routes>
                    {permisos &&
                        permisos.inicio &&
                        permisos.inicio.activo === true && (
                            <Route path='/' element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.modulos &&
                        permisos.reportes.modulos.sanitaria &&
                        permisos.reportes.modulos.sanitaria.activo === true && (

                            <Route path='/sanitaria' element={<ProtectedRoute><Sanitaria user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}



                    {/* Ruta /login, solo mostrar si el usuario no está autenticado */}
                    <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
                </Routes>
            </main>
        </div>
    );
};

export default MainRouter;