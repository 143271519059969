import React from 'react';
import { createRoot } from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Notifications } from "@mantine/notifications";

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/charts/styles.css'; // Asegúrate de que esto esté aquí
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider withCssVariables>
        <Notifications position="top-right" />
        <App />
      </MantineProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
