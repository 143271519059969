import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Group, Button, Title, Drawer, Tabs } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';


import FiltrosSanitaria2 from '../components/FiltrosSanitaria2';
import TablaSanitaria from '../components/TablaSanitaria';
import TablaPorOrigen from '../components/TablaPorOrigen';
import BarChartPorOrigen from '../components/BarChartPorOrigen';
import BarChartPorEstudio from '../components/BarChartPorEstudio';
import TablaPorEstudio from '../components/TablaPorEstudio';
import TablaPorSeguimiento from '../components/TablaPorSeguimiento';
import DonutChartTipoSeguimiento from '../components/DonutChartTipoSeguimiento';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";

function Sanitaria() {
    const [filters, setFilters] = useState({
        periodo: "2024",
        mes: "1",
        sucursal: "LESP",
        tipo_seguimiento: [],
        origen: [],
        estudio: [],
    });

    const [tableData, setTableData] = useState([]);
    const [sumaCuentaMuestra, setSumaCuentaMuestra] = useState(0);
    const [sumaCuentaMuestraActiva, setSumaCuentaMuestraActiva] = useState(0);
    const [sumaCuentaMuestraRechazada, setSumaCuentaMuestraRechazada] = useState(0);

    const [opened, { open, close }] = useDisclosure(false);
    const dispatch = useDispatch();



    const handleSearch = async () => {
        try {
            dispatch(ShowLoading());
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/consultatabla`, filters);
            /*const response = await axios.post('http://localhost:5000/consultatabla', filters);*/
            setTableData(response.data);

            const suma = response.data.reduce((total, item) => total + item.cuenta_muestra, 0);
            setSumaCuentaMuestra(suma);

            const sumaActiva = response.data.reduce((total, item) => total + item.cuenta_muestra_estatus_ACTIVA, 0);
            setSumaCuentaMuestraActiva(sumaActiva);

            const sumaRechazada = response.data.reduce((total, item) => total + item.cuenta_muestra_estatus_RECHAZADA, 0);
            setSumaCuentaMuestraRechazada(sumaRechazada);

        } catch (error) {
            console.error('Error al realizar la consulta:', error);
        } finally {
            dispatch(HideLoading());
        }
    };

    return (
        <div>
            <Card style={{ backgroundColor: '#F0F0F0' }}>
                <Drawer opened={opened} onClose={close} position='right' title="Filtros">

                    <Group grow mb={10}>
                        <FiltrosSanitaria2
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </Group>
                    <Group>
                        <Button
                            color="#005187"
                            onClick={() => {
                                handleSearch();
                                close();
                            }}
                        >
                            Buscar
                        </Button>
                    </Group>

                </Drawer>

                <Group
                    gap="xs"
                    mt={'-15px'}
                >
                    <Button
                        onClick={open}
                        variant='filled'
                        
                    >
                        Filtros de Búsqueda
                    </Button>

                </Group>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        marginTop: '15px',
                        marginBottom: '15px',
                    }}
                >
                    <Group
                        style={{
                            width: '100%',
                            height: '100%', // Asegúrate de que el grupo ocupa el 100% del alto del contenedor padre
                            display: 'flex',
                            alignItems: 'stretch' // Asegúrate de que los elementos hijos ocupen todo el alto del grupo
                        }}
                    >
                        <Card
                            shadow="sm"
                            withBorder
                            style={{
                                flex: '1 1 0',
                                background: 'grey',
                                color: 'white',
                                marginRight: '1px',
                                border: '2px solid #DEE2E6',
                                minWidth: '200px', // Opcional, establecer un ancho mínimo para evitar que se reduzca demasiado
                            }}
                        >
                            <Group
                                gap="xs"
                            >
                                <Title order={4}>Muestras:</Title>
                                <Title order={4}>
                                    {sumaCuentaMuestra}
                                </Title>
                            </Group>
                        </Card>
                        <Card
                            shadow="sm"
                            withBorder
                            style={{
                                flex: '1 1 0',
                                background: 'green',
                                color: 'white',
                                marginRight: '1px',
                                border: '2px solid #DEE2E6',
                                minWidth: '200px', // Opcional, establecer un ancho mínimo para evitar que se reduzca demasiado
                            }}
                        >
                            <Group
                                gap="xs"
                            >
                                <Title order={4}>Muestras Activas:</Title>
                                <Title order={4}>
                                    {sumaCuentaMuestraActiva}
                                </Title>
                            </Group>
                        </Card>
                        <Card
                            shadow="sm"
                            withBorder
                            style={{
                                flex: '1 1 0',
                                background: 'crimson',
                                color: 'white',
                                marginRight: '1px',
                                border: '2px solid #DEE2E6',
                                minWidth: '200px', // Opcional, establecer un ancho mínimo para evitar que se reduzca demasiado
                            }}
                        >
                            <Group
                                gap="xs"
                            >
                                <Title order={4}>Muestras Rechazadas:</Title>
                                <Title order={4}>
                                    {sumaCuentaMuestraRechazada}
                                </Title>
                            </Group>
                        </Card>
                    </Group>
                </div>

            
                <div
                    //shadow="sm"
                    //withBorder
                    style={{

                        //border: '2px solid #DEE2E6',
                        minHeight: '550px',

                        maxHeight: '550px',
                        overflowY: 'auto',
                        background: '#F0F0F0'

                    }}
                >
                    <Tabs
                        defaultValue="Programas"
                        variant="pills"
                        color="blue"

                    >
                        <Tabs.List>
                            <Tabs.Tab value="Programas">
                                Programa
                            </Tabs.Tab>
                            <Tabs.Tab value="Orígenes">
                                Orígenes
                            </Tabs.Tab>
                            <Tabs.Tab value="Paquetes">
                                Paquetes
                            </Tabs.Tab>
                            <Tabs.Tab value="Detalle">
                                Detalle
                            </Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="Programas">
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    marginTop: '15px',
                                    marginBottom: '15px'
                                }}
                            >
                                <Group style={{ width: '100%', alignItems: 'flex-start' }}>
                                    <Card
                                        shadow='sm'
                                        padding="lg"
                                        withBorder
                                        style={{
                                            flex: '1 1 0',
                                            marginRight: '1px',
                                            border: '2px solid #DEE2E6',
                                            minWidth: '300px', // Opcional, establece un ancho mínimo para evitar que se encojan demasiado
                                        }}
                                    >
                                        <DonutChartTipoSeguimiento data={tableData} /> {/* Renderiza la gráfica dentro del Card */}
                                    </Card>
                                    <Card
                                        shadow='sm'

                                        padding="lg"
                                        withBorder
                                        style={{
                                            flex: '1 1 0',
                                            marginRight: '1px',
                                            border: '2px solid #DEE2E6',
                                            minWidth: '300px', // Opcional, establece un ancho mínimo para evitar que se encojan demasiado
                                        }}
                                    >
                                        <TablaPorSeguimiento data={tableData} /> {/* Renderiza la tabla dentro del Card */}
                                    </Card>
                                </Group>
                            </div>
                        </Tabs.Panel>

                        <Tabs.Panel value="Orígenes">
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    marginTop: '15px',
                                    marginBottom: '15px'
                                }}
                            >
                                <Group style={{ width: '100%', alignItems: 'flex-start' }}>
                                    <Card
                                        padding="lg"
                                        withBorder
                                        style={{
                                            flex: '1 1 0',
                                            marginRight: '1px',
                                            //border: '2px solid #DEE2E6',
                                            minWidth: '300px', // Opcional, establece un ancho mínimo para evitar que se encojan demasiado
                                        }}
                                    >
                                        <BarChartPorOrigen data={tableData} /> {/* Renderiza la gráfica dentro del Card */}
                                    </Card>
                                    <Card
                                        padding="lg"
                                        withBorder
                                        style={{
                                            flex: '1 1 0',
                                            marginRight: '1px',
                                            //border: '2px solid #DEE2E6',
                                            minWidth: '300px', // Opcional, establece un ancho mínimo para evitar que se encojan demasiado
                                        }}
                                    >
                                        <TablaPorOrigen data={tableData} /> {/* Renderiza la tabla dentro del Card */}
                                    </Card>
                                </Group>
                            </div>


                        </Tabs.Panel>

                        <Tabs.Panel value="Paquetes">
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    marginTop: '15px',
                                    marginBottom: '15px'
                                }}
                            >
                                <Group style={{ width: '100%', alignItems: 'flex-start' }}>
                                    <Card
                                        shadow="sm"
                                        padding="lg"
                                        radius="md"
                                        withBorder
                                        style={{
                                            flex: '1 1 0',
                                            marginRight: '1px',
                                            //border: '2px solid #DEE2E6',
                                            minWidth: '300px', // Opcional, establece un ancho mínimo para evitar que se encojan demasiado
                                        }}
                                    >
                                        <BarChartPorEstudio data={tableData} /> {/* Renderiza la gráfica dentro del Card */}
                                    </Card>
                                    <Card
                                        shadow="sm"
                                        padding="lg"
                                        radius="md"
                                        withBorder
                                        style={{
                                            flex: '1 1 0',
                                            marginRight: '1px',
                                            //border: '2px solid #DEE2E6',
                                            minWidth: '300px', // Opcional, establece un ancho mínimo para evitar que se encojan demasiado
                                        }}
                                    >
                                        <TablaPorEstudio data={tableData} /> {/* Renderiza la tabla dentro del Card */}
                                    </Card>
                                </Group>
                            </div>

                        </Tabs.Panel>
                        <Tabs.Panel value="Detalle">
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    marginTop: '15px',
                                    marginBottom: '15px'
                                }}
                            >
                                <Group style={{ width: '100%', alignItems: 'flex-start' }}>
                                    <Card
                                        shadow="sm"
                                        padding="lg"
                                        radius="md"
                                        withBorder
                                        style={{
                                            flex: '1 1 0',
                                            marginRight: '1px',
                                            border: '2px solid #DEE2E6',
                                            minWidth: '300px', // Opcional, establece un ancho mínimo para evitar que se encojan demasiado
                                        }}
                                    >
                                        <TablaSanitaria data={tableData} />

                                    </Card>

                                </Group>
                            </div>

                        </Tabs.Panel>
                    </Tabs>
                </div>





            </Card>

        </div>
    );
}

export default Sanitaria;