import React, { useEffect, useState } from 'react';
import { Group, Code, ScrollArea, rem, Text, TypographyStylesProvider, UnstyledButton, Divider } from '@mantine/core';
import {
    IconReportMoney,
    IconClipboardText,
    IconHome,
    IconBook2,
    IconReportAnalytics,
    IconAdjustments,
    IconLogout,
} from '@tabler/icons-react';
//import LinksGroup from '../components/LinksGroup';
import { LinksGroup } from './LinksGroup';
import classes from './NavbarNested.module.css';
import { UserButton } from './UserButton';
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from '../redux/alertsSlice';



const mockdata = [
    {
        label: 'Inicio',
        icon: IconHome,
        //initiallyOpened: true,
        links: [
            { label: 'Ir a inicio', link: '/' },
        ],
    }, {
        label: 'Reportes',
        icon: IconBook2,
        //initiallyOpened: true,
        links: [
            { label: 'Sanitaria', link: '/sanitaria' },
        ],
    },

];

export function SideNavbar({ user, permisos }) {
    const [loaded, setLoaded] = useState(false); // Estado local para controlar la carga de permisos
    const dispatch = useDispatch();

    useEffect(() => {
        if (user && permisos) {
            // Verificar si los permisos y el usuario están disponibles
            setLoaded(true); // Marcar que los permisos han sido cargados
        }
    }, [user, permisos]);

    // Renderizar el menú solo después de cargar los permisos y el usuario
    useEffect(() => {
        // Mostrar indicador de carga mientras se revisan los permisos
        if (!loaded) {
            dispatch(ShowLoading());
        } else {
            // Ocultar indicador de carga una vez que se han revisado los permisos
            dispatch(HideLoading());
        }
    }, [loaded, dispatch]);

    // Renderizar el menú solo después de cargar los permisos y el usuario
    if (!loaded) {
        return null;
        //dispatch(ShowLoading());
    }

   

    const menuItems = mockdata.map((item) => {
        if (item.label === 'Inicio') {
            return <LinksGroup {...item} key={item.label} />;
        }
        

        if (item.label === 'Reportes') {
            // Filtrar elementos del catálogo
            const reporteItems = item.links.filter((link) => {
                // Obtener el nombre del catálogo del enlace
                const reporte = link.link.split('/')[1];
                // Verificar si el catálogo está presente en los permisos y si está activo
                return permisos &&
                    permisos.reportes &&
                    permisos.reportes.modulos &&
                    permisos.reportes.modulos[reporte] &&
                    permisos.reportes.modulos[reporte].activo === true;
            });
            //console.log("Reporte Items:", reporteItems);

            // Si no hay elementos en el catálogo, no mostrarlo
            if (reporteItems.length === 0) {
                return null;
            }
            // Retornar el grupo de enlaces del catálogo con los elementos filtrados
            return <LinksGroup {...item} links={reporteItems} key={item.label} />;
        }

        // Si no es el catálogo ni 'Polizas', simplemente retornar el grupo de enlaces
        return <LinksGroup {...item} key={item.label} />;
    });



    return (
        <nav className={classes.navbar}>
            <div className={classes.header}>
                <UserButton></UserButton>
            </div>
            <ScrollArea style={{ maxHeight: 'calc(100vh - 310px)' }} className={classes.links}>
                <div className={classes.linksInner}>{menuItems}</div>
            </ScrollArea>

            <div className={classes.footer}>
                <Group justify="right">
                    <Code fw={700}>insadisa.com</Code>
                </Group>
            </div>
        </nav>
    );
}

export default SideNavbar;