import React, { useState } from 'react';
import { Table } from "@mantine/core";
import { Button, Group, Pagination, Title } from '@mantine/core';
import CSVDownloader from 'react-csv-downloader';



const TablaPorEstudio = ({ data }) => {
    const [activePage, setActivePage] = useState(1);
    const rowsPerPage = 6;

    // Agrupar y sumar cuenta_muestra por nombre_estudio
    const dataPorEstudio = data.reduce((acc, item) => {
        const { nombre_estudio, cuenta_muestra_estatus_ACTIVA } = item;
        if (!acc[nombre_estudio]) {
            acc[nombre_estudio] = 0;
        }
        acc[nombre_estudio] += cuenta_muestra_estatus_ACTIVA;
        return acc;
    }, {});

    // Convertir el objeto resultante en un array para renderizarlo en la tabla
    const dataArray = Object.keys(dataPorEstudio).map((key) => ({
        nombre_estudio: key,
        sumaCuentaMuestraPorEstudio: dataPorEstudio[key]
    }));

    // Calcular los datos para la página actual
    const startIndex = (activePage - 1) * rowsPerPage;
    const paginatedData = dataArray.slice(startIndex, startIndex + rowsPerPage);

    // Definir las columnas para el CSV
    const csvHeaders = [
        { id: 'nombre_estudio', displayName: 'Origen' },
        { id: 'sumaCuentaMuestraPorEstudio', displayName: 'Muestras' },
    ];

    const csvData = dataArray.map((data) => {
        const nombre_estudio = data.nombre_estudio ? data.nombre_estudio.replace(/,/g, '') : '';
    
        return {
            nombre_estudio: nombre_estudio,
            sumaCuentaMuestraPorEstudio: data.sumaCuentaMuestraPorEstudio,
        };
    });
    

    return (
        <div>
            <Group justify="space-between">
                <Title order={4}>
                    Tabla por Paquete
                </Title>

            </Group>

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead>
                    <tr>
                        <th>Paquete</th>
                        <th>Muestras</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.nombre_estudio}</td>
                            <td>{item.sumaCuentaMuestraPorEstudio}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Group justify="flex-end" mb="md">
                <CSVDownloader
                    filename={'tabla_por_estudio'}
                    separator=","
                    wrapColumnChar=""
                    columns={csvHeaders}
                    datas={csvData}
                >
                    <Button>Descargar</Button>
                </CSVDownloader>
                <Pagination
                    page={activePage}
                    onChange={setActivePage}
                    total={Math.ceil(dataArray.length / rowsPerPage)}
                />
            </Group>
        </div>
    );
};

export default TablaPorEstudio;
